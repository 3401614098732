<template>
  <div class="guidance">
    <div class="pt-4 d-flex mb-4">
      <h4 class="title">新手引导</h4>
      <el-button class="submit-button" type="primary" @click="$router.push('new-guidance/add')">新增</el-button>
    </div>
    <el-table
      :data="guidanceInfos"
      style="width: 100%"
      border>
      <el-table-column label="内容">
        <template slot-scope="scope">
          <div class="guidance-content">
            <div class="guidance-img">
              <img v-if="scope.row.coverImage" :src="scope.row.coverImage" alt="新手引导图">
            </div>
            <div style="flex:1;padding-left:7px;">
              <p class="title">{{scope.row.title}}</p>
              <p class="content">{{scope.row.abstract}}</p>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="发布时间" width="130" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.publishedAt ? formatTime(scope.row.publishedAt) : '--' }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="150" align="center">
        <template slot-scope="scope">
          <el-button class="btn-small" type="text" @click="$router.push('new-guidance/edit?id=' + scope.row.id + '&ptime=' + scope.row.publishedAt)">编辑</el-button>
          <el-button class="btn-small" type="text" @click="operation(scope.row)">发布</el-button>
          <el-button class="btn-small danger" type="text" @click="handleDelete(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import {
  guideList,
  guidePublish,
  guideDelete
} from '@/api/tips'
export default {
  name: 'new-guidance',
  components: {
  },
  data () {
    return {
      guidanceInfos: []
    }
  },
  created () {
    this.init()
  },
  methods: {
    init () {
      guideList().then(
        res => {
          this.guidanceInfos = res.data.items
        }
      )
    },
    formatTime (timestamp) {
      var d = new Date(timestamp * 1000)
      var YYYY = d.getFullYear()
      var MM = d.getMonth() + 1
      var DD = d.getDate()
      return YYYY + '-' + MM + '-' + DD
    },
    async operation (row) {
      if (
        row.title === '' ||
        row.content === '' ||
        row.coverImage === '' ||
        row.endpoints.length === 0
      ) {
        this.$notify({
          title: '警告',
          message: '内容不全, 无法发布',
          type: 'error'
        })
        return
      }
      try {
        await guidePublish(row.id)
        this.$notify({
          title: '成功',
          message: '发布成功',
          type: 'success'
        })
        this.init()
      } catch (err) {
        console.log(err)
      }
    },
    async handleDelete (row) {
      try {
        await guideDelete(row.id)
        this.$notify({
          title: '成功',
          message: '删除成功',
          type: 'success'
        })
        this.init()
      } catch (err) {
        console.log(err)
      }
    }
  }
}
</script>

<style lang="less" scoped>
.guidance .title {
  font-weight: 700;
  font-size: 20px;
  flex: 1;
}
.guidance-content {
  display: flex;
  .guidance-img {
    width: 118px;
    height: 50px;
    flex-shrink: 0;
  }
  img {
    width: 118px;
    height: 50px;
    object-fit: cover;
  }
  .title {
    width: 538px;
    margin-bottom: 0;
    font-size: 14px;
    color: #555555;
    white-space: nowrap;
    overflow: hidden;
    text-overflow:ellipsis;
  }
  .content {
    width: 538px;
    margin-bottom: 0;
    color: #999999;
    white-space: nowrap;
    overflow: hidden;
    text-overflow:ellipsis;
  }
}
.guidance .btn-small {
  padding: 0;
  &.el-button--text {
    font-size: 14px;
    color: #3775F6;
    &:focus {
      outline: none;
    }
  }
  &.danger {
    color: #F56C6C;
  }
  /deep/span:focus-visible {
    outline: none;
  }
}
.guidance .btn-small.el-button+.el-button {
  margin-left: 3px;
}
.guidance .text-btn {
  font-size: 14px;
  color: #3775F6;
}
.guidance {
  /deep/.el-table--border td,
  /deep/.el-table--border th,
  /deep/.el-table__body-wrapper .el-table--border.is-scrolling-left~.el-table__fixed,
  /deep/.el-table td, .el-table th.is-leaf,
  /deep/.el-table--border, .el-table--group {
    border-color: #D9D9D9;
  }
  /deep/.el-table--border::after,
  /deep/.el-table--group::after,
  /deep/.el-table::before {
    background-color: #D9D9D9;
  }
  /deep/.el-table th>.cell {
    color: #333;
    text-align: center;
  }

}
</style>
